import { LoanType } from "@prisma/client";
import { z } from "zod";
import { schemaCurrency, schemaSelect } from "~/modules/common/schemas";
import { LoanPurpose, QuestionInlineSlug } from "../../enums";
import { schemaDate, schemaNumber } from "../../schemas";

/*
 * This file contains custom schemas used in the questions object.
 * You should try to avoid as much as possible to use custom schemas,
 * and prefer using the schemas automatically generated with the questionsInlines definition.
 */

const customSchemaAssetLoansBase = z.object({
  [QuestionInlineSlug.AssetLoanEndDate]: schemaDate,
  [QuestionInlineSlug.AssetLoanRate]: schemaNumber,
  [QuestionInlineSlug.AssetLoanGuarantee]: schemaSelect,
});

export const customSchemaAssetLoans = z.discriminatedUnion(
  QuestionInlineSlug.AssetLoanLoanType,
  [
    customSchemaAssetLoansBase.extend({
      [QuestionInlineSlug.AssetLoanLoanType]: z.literal(LoanType.depreciable),
      [QuestionInlineSlug.AssetLoanMonthlyPaymentAmount]: schemaCurrency,
    }),
    customSchemaAssetLoansBase.extend({
      [QuestionInlineSlug.AssetLoanLoanType]: z.literal(LoanType.in_fine),
      [QuestionInlineSlug.AssetLoanAmount]: schemaCurrency,
    }),
  ],
);

const customSchemaAdditionalLoanConsumer = z.object({
  [QuestionInlineSlug.AdditionalLoanPurpose]: z.literal(LoanPurpose.Consumer),
  [QuestionInlineSlug.AdditionalLoanMonthlyPaymentAmount]: schemaCurrency,
  [QuestionInlineSlug.AdditionalLoanEndDate]: schemaDate,
  [QuestionInlineSlug.AdditionalLoanRate]: schemaNumber,
});

const customSchemaAdditionalLoanRealEstateDepreciable = z.object({
  [QuestionInlineSlug.AdditionalLoanPurpose]: z.literal(LoanPurpose.RealEstate),
  [QuestionInlineSlug.AdditionalLoanType]: z.literal(LoanType.depreciable),
  [QuestionInlineSlug.AdditionalLoanMonthlyPaymentAmount]: schemaCurrency,
  [QuestionInlineSlug.AdditionalLoanEndDate]: schemaDate,
  [QuestionInlineSlug.AdditionalLoanRate]: schemaNumber,
  [QuestionInlineSlug.AdditionalLoanGuarantee]: schemaSelect,
});

const customSchemaAdditionalLoanRealEstateInFine = z.object({
  [QuestionInlineSlug.AdditionalLoanPurpose]: z.literal(LoanPurpose.RealEstate),
  [QuestionInlineSlug.AdditionalLoanType]: z.literal(LoanType.in_fine),
  [QuestionInlineSlug.AdditionalLoanAmount]: schemaCurrency,
  [QuestionInlineSlug.AdditionalLoanEndDate]: schemaDate,
  [QuestionInlineSlug.AdditionalLoanRate]: schemaNumber,
  [QuestionInlineSlug.AdditionalLoanGuarantee]: schemaSelect,
});

const validateConsumer = z
  .object({
    [QuestionInlineSlug.AdditionalLoanPurpose]: z.literal(LoanPurpose.Consumer),
  })
  .passthrough();

const validateRealEstate = z
  .object({
    [QuestionInlineSlug.AdditionalLoanPurpose]: z.literal(
      LoanPurpose.RealEstate,
    ),
  })
  .passthrough();

/**
 * Inspired from : https://github.com/colinhacks/zod/issues/2593#issuecomment-1644795995
 * This is necessary because z.discriminatedUnion does not support nested discriminatedUnion
 */
export const customSchemaAdditionalLoans = z
  .discriminatedUnion(QuestionInlineSlug.AdditionalLoanPurpose, [
    validateConsumer,
    validateRealEstate,
  ])
  .transform((data, ctx) => {
    let res;
    if (
      data[QuestionInlineSlug.AdditionalLoanPurpose] === LoanPurpose.Consumer
    ) {
      res = customSchemaAdditionalLoanConsumer.safeParse(data);
    } else if (
      data[QuestionInlineSlug.AdditionalLoanPurpose] === LoanPurpose.RealEstate
    ) {
      res = z
        .discriminatedUnion(QuestionInlineSlug.AdditionalLoanType, [
          customSchemaAdditionalLoanRealEstateDepreciable,
          customSchemaAdditionalLoanRealEstateInFine,
        ])
        .safeParse(data);
    } else {
      throw new Error("Invalid Loan Type");
    }
    if (!res.success) {
      res.error.issues.forEach(ctx.addIssue);
      return z.NEVER;
    }
    return res.data;
  });
