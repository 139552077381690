import { z } from "zod";
import {
  assetTypeOptions,
  booleanOptions,
} from "~/modules/common/static/common/formSelectOption";
import {
  casePurposeOptions,
  caseTypeOptions,
  companyTypeOptions,
} from "~/modules/common/static/patrimonial/formSelectOption";
import { COUNTRIES_FR_AND_DOMTOM } from "~/modules/common/utils/countries";
import { QuestionSimpleSlug } from "../../enums";
import { type Question } from "../../interfaces";
import { schemaSiretInput, schemaSurface } from "../../schemas";
import { CommonQuestions } from "../common/questions";

export const questionsPatrimonial: Question[] = [
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CasePurpose,
    question: "Quel est votre projet ?",
    type: "btn-wrap",
    placeholder: "",
    options: casePurposeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanyType,
    question: "Qui emprunte ?",
    type: "btn",
    placeholder: "",
    options: caseTypeOptions,
    infoBox:
      "Si vous n’avez pas encore de SIRET, par exemple si la société est en cours de constitution, alors choisissez personne physique.",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.FinancingRequestedAmount,
    question: "De quel montant avez-vous besoin (€) ?",
    type: "currency-positive",
    placeholder: "300 000",
    unit: "€",
    label: "Montant",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.FinancingUsageDescription,
    question: "Décrivez-nous votre projet",
    type: "textarea",
    placeholder: "Écrire ici...",
    label: "Votre projet :",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetAddress,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel bien souhaitez vous mettre en garantie ?"
        : "Où se situe votre bien immobilier ?";
    },
    infoBox: "Si votre projet est d’acheter un bien, saisissez-le ici.",
    type: "address",
    countryList: COUNTRIES_FR_AND_DOMTOM,
    placeholder: "",
    label: "Adresse du bien :",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetValue,
    question: "Quelle est sa valeur ?",
    type: "currency-positive",
    placeholder: "600 000",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetSurface,
    question: "Quelle est sa surface ?",
    type: "number",
    placeholder: "100",
    unit: "m²",
    customSchema: schemaSurface,
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data > 500) {
        return "S’agit-il bien de la surface de votre bien ?";
      }
      return null;
    },
  },
  CommonQuestions[QuestionSimpleSlug.AssetLoans],
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetType,
    question: "Quel est la typologie de ce bien ?",
    type: "btn",
    placeholder: "",
    options: assetTypeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetRentAnnualAmount,
    question: "Quel revenu locatif en retirez-vous annuellement ?",
    infoBox:
      "Si vous êtes en train d’acheter le bien, renseignez le loyer futur.",
    type: "currency-positive",
    placeholder: "15 000",
    unit: "€",
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data < 1000 && parsedValue.data !== 0) {
        return "S’agit-il du revenu que vous en retirez annuellement ?";
      }

      return null;
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetAddNew,
    question: "Avez-vous d’autres biens immobiliers?",
    type: "btn",
    placeholder: "",
    options: booleanOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanySiret,
    type: "siret",
    question: "Votre SIRET",
    placeholder: "",
    getWarning: (value) => {
      const parsedValue = schemaSiretInput.parse(value);

      if (!parsedValue.companyName) {
        return `S’agit-il bien de votre société ? \n
        N° SIRET : \n ${parsedValue.siret}`;
      } else {
        return `S’agit-il bien de votre société ? \n
        N° SIRET : \n ${parsedValue.siret} \n
        Nom de la société : \n ${parsedValue.companyName} `;
      }
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanyTaxType,
    type: "btn",
    question: "Est-ce que cette société est à IS ou IR ?",
    placeholder: "",
    options: companyTypeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanyResult,
    type: "currency",
    question: "Quel est le dernier résultat d’exploitation ?",
    placeholder: "100 000",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanyDepreciationCharge,
    type: "currency",
    question: "Quelle est la dotation aux amortissements ?",
    placeholder: "40 000",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanyNetResult,
    type: "currency",
    question: "Quel est le résultat net de la société ( 2072 ) ?",
    placeholder: "0,00",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanyLoanInterest,
    type: "currency",
    question: "Combien la société paie-t-elle d’intérêts d’emprunt ?",
    placeholder: "0,00",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CompanyAccountValue,
    question: "Y’a-t-il des comptes courants d’associé à rembourser ?",
    type: "currency",
    placeholder: "10000",
    skipValue: 0,
    unit: "€",
  },
];
